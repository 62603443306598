<template>
  <div>
    <!-- <div class="breadcrumbs-area">
      <h3 class="red--text">School Setup</h3>
      <ul>
        <li>
          <a href="index.html">Qualifier</a>
        </li>
        <li>All Qualifiers</li>
      </ul>
    </div>
    <div></div>
    <div> -->

    <DataTableComponent :table="table"></DataTableComponent>
    <!-- </div> -->
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      table: {
        modal: "Qualifier",
        path: "qualifier",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "en.title",
            width: "10%",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.title",
            width: "10%",
          },
          {
            text: this.$i18n.t("Notes"),
            value: "notes",
            sortable: false,
            width: "90%",
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Subject Qualifiers"),
        apiEndPoints: {
          list: "qualifier",
          create: "qualifier/store",
          edit: "qualifier/update",
          delete: "qualifier/destroy",
        },
        editedItem: {
          title: "",
          Notes: "",
          en: {
            title: "",
          },
          ar: {
            title: "",
          },
        },
        permissions: {
          list: "list-qualifier",
          add: "add-qualifier",
          edit: "edit-qualifier",
          delete: "delete-qualifier",
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {},
};
</script>

<style></style>
